import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 40px 20px;
  max-width: 800px;
  margin: 40px auto;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;
  color: #343a40;
  text-align: center;
  margin-bottom: 30px;
`;

const Section = styled.section`
  margin-bottom: 25px;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: #495057;
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-size: 14px;
  color: #6c757d;
  line-height: 1.6;
`;

const List = styled.ul`
  padding-left: 20px; // Añade un poco de margen a la izquierda para alinear la lista
`;

const ListItem = styled.li`
  font-size: 14px;
  color: #6c757d;
  line-height: 1.6;
`;

const PrivacyPolicy = () => {
  return (
    <Container>
      <Title>Política de Privacidad</Title>
      <Section>
        <Text>
          En Darpha Fire Solutions S.A. de C.V. (en adelante, "Darpha"), valoramos tu privacidad y nos comprometemos a proteger la información personal que compartes con nosotros. Esta Política de Privacidad tiene como objetivo informarte sobre los tipos de datos que recopilamos, cómo los utilizamos y cómo garantizamos su seguridad, así como tus derechos en relación con tu información personal.
        </Text>
      </Section>
      <Section>
        <SectionTitle>Vigencia</SectionTitle>
        <Text>Vigente a partir del 20 de septiembre de 2024</Text>
      </Section>
      <Section>
        <SectionTitle>Información que recopilamos</SectionTitle>
        <Text>
          Recopilamos y tratamos la siguiente información personal:
        </Text>
        <List>
          <ListItem>Nombre</ListItem>
          <ListItem>Correo electrónico</ListItem>
          <ListItem>Contraseña</ListItem>
          <ListItem>Rol de trabajo</ListItem>
          <ListItem>En el caso de supervisores, la empresa y planta donde trabajan.</ListItem>
        </List>
      </Section>
      <Section>
        <SectionTitle>Uso de la información</SectionTitle>
        <Text>
        La información que recopilamos se utiliza para los siguientes fines:
        </Text>
        <List>
          <ListItem>Autenticación de usuarios: Verificar la identidad de los usuarios al iniciar sesión en la aplicación.</ListItem>
          <ListItem>Envío de notificaciones: Informar a los usuarios sobre actualizaciones relevantes y recordatorios.</ListItem>
          <ListItem>Procesos internos: Facilitar la generación de reportes de mantenimiento y otros procedimientos administrativos.</ListItem>
        </List>
      </Section>
      <Section>
        <SectionTitle>Compartición de información</SectionTitle>
        <Text>
        Darpha no comparte, vende ni alquila tus datos personales a terceros. La información recopilada se utiliza exclusivamente para los fines establecidos en esta Política.
        </Text>
      </Section>
      <Section>
        <SectionTitle>Seguridad de los datos</SectionTitle>
        <Text>
        Implementamos medidas de seguridad técnicas y organizativas adecuadas para proteger tu información personal contra accesos no autorizados, divulgación, alteración o destrucción. Nos comprometemos a usar tus datos únicamente para los fines especificados y garantizamos que no se lucrará con tu información.
        </Text>
      </Section>
      <Section>
        <SectionTitle>Derechos de los usuarios</SectionTitle>
        <Text>
        De acuerdo con la legislación aplicable, los usuarios tienen derecho a:
        </Text>
        <List>
          <ListItem>Acceder a sus datos: Solicitar información sobre los datos personales que mantenemos.</ListItem>
          <ListItem>Modificar sus datos: Actualizar o corregir la información personal que tenemos.</ListItem>
          <ListItem>Eliminar sus datos: Solicitar la eliminación de su información personal, en la medida permitida por la ley.</ListItem>
        </List>
        <Text>Los usuarios pueden ejercer estos derechos a través de un apartado dedicado en la aplicación, donde podrán gestionar su información personal.</Text>
      </Section>
      <Section>
        <SectionTitle>Cookies y tecnologías de rastreo</SectionTitle>
        <Text>
        Utilizamos cookies y tecnologías similares para mejorar la experiencia del usuario, guardar la sesión de inicio, almacenar tokens de autenticación y optimizar el rendimiento de la aplicación. Los usuarios pueden gestionar sus preferencias de cookies a través de la configuración de su navegador.
        </Text>
      </Section>
      <Section>
        <SectionTitle>Contacto</SectionTitle>
        <Text>
        Si tienes preguntas, inquietudes o solicitudes relacionadas con esta Política de Privacidad, puedes ponerte en contacto con nosotros a través del siguiente correo electrónico:
        </Text>
        <List>
          <ListItem>Soporte: soporte.darphafiresolutions@gmail.com</ListItem>
        </List>
      </Section>
      <Section>
        <SectionTitle>Modificaciones a la Política de Privacidad</SectionTitle>
        <Text>
        Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Cualquier cambio será notificado a los usuarios a través de la aplicación y se recomienda revisarla periódicamente para estar al tanto de cómo protegemos tu información.
        </Text>
      </Section>
    </Container>
  );
};

export default PrivacyPolicy;
