import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { isDesktop, isMobile } from 'react-device-detect';
import Sidebar from '../Global/components/Sidebar';
import BottomNavBar from '../Global/components/BottomNavBar.js';
import './styles/Profile.css'; // Asegúrate de que la ruta sea la correcta

const Profile = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const navigate = useNavigate();

  const getIdFromToken = () => {
    let token = localStorage.getItem('token');
    if (!token) {
      token = sessionStorage.getItem('token');
    }
    if (!token) {
      setError('No se encontró el token de autenticación');
      return null;
    }
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.idusuario;
    } catch (error) {
      setError('Error al decodificar el token');
      return null;
    }
  };

  useEffect(() => {
    const userId = getIdFromToken();
    if (!userId) return;
    const fetchProfile = async () => {
      let token = localStorage.getItem('token');
      if (!token) {
        token = sessionStorage.getItem('token');
      }
      if (!token) {
        setError('No se encontró el token de autenticación');
        return;
      }
      try {
        const response = await axios.get(`https://api-darpha-production.up.railway.app/api/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setName(response.data.nombreusuario);
        setEmail(response.data.correoelectronico);
      } catch (error) {
        setError('Error al cargar los datos del perfil');
      }
    };
    fetchProfile();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSaveProfile = async (event) => {
    event.preventDefault();
    setError('');
    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden');
      return;
    }
    try {
      let userId = localStorage.getItem('userId');
      if (!userId) {
        userId = sessionStorage.getItem('userId');
      }
      if (!userId) {
        setError('No se encontró el ID de usuario');
        return;
      }
      await axios.put(`https://api-darpha-production.up.railway.app/api/users/${userId}`, {
        nombreusuario: name,
        correoelectronico: email,
        contrasena: password || undefined,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setIsEditing(false);
      setShowPasswordFields(false);
    } catch (error) {
      setError('Error al actualizar el perfil. Intenta de nuevo.');
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handlePasswordChange = () => {
    setShowPasswordFields(true);
  };

  let layout;
  if (isDesktop) {
    layout = <Sidebar />;
  } else if (isMobile) {
    layout = <BottomNavBar />;
  }

  return (
    <div className="main-container-login">
      {layout}
      <div className="container-login-settings">
        <h1 className="title-login-settings">Perfil de Usuario</h1>
        {!isEditing ? (
            <>
            <div className="container-user-info">
              <div className="profile-image-container">
              <i class="fa-solid fa-user profile-image"></i>
              </div>
              <p className="label-profile-settings">Nombre de Usuario</p>
              <p className="label-username">{name}</p>
              <p className="label-profile-settings">Email</p>
              <p className="label-email">{email}</p>
              <div className="edit-password-container">
                <span onClick={handleEdit}>Editar</span>
                <span onClick={handlePasswordChange}>Cambiar Contraseña</span>
              </div>
              </div>
            </>
          ) : (
            <>
              <label className="label-login-settings">Nombre de usuario</label>
              <input
                className="input-login-settings"
                type="text"
                placeholder="Nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <p className="label-login-settings">{name}</p> {/* Mostrar nombre debajo del campo */}

              <label className="label-login-settings">Correo electrónico</label>
              <input
                className="input-login-settings"
                type="email"
                placeholder="Correo electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p className="label-login-settings">{email}</p> {/* Mostrar correo debajo del campo */}
            </>
          )}

        {showPasswordFields && (
          <>
            <div className="password-container-login-settings">
              <input
                className="password-input-login-settings input-login-settings"
                type={showPassword ? 'text' : 'password'}
                placeholder="Nueva Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FontAwesomeIcon
                className="toggle-password-icon-login-settings"
                icon={showPassword ? faEyeSlash : faEye}
                onClick={togglePasswordVisibility}
              />
            </div>
            <div className="password-container-login-settings">
              <input
                className="password-input-login-settings input-login-settings"
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirmar Nueva Contraseña"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <FontAwesomeIcon
                className="toggle-password-icon-login-settings"
                icon={showConfirmPassword ? faEyeSlash : faEye}
                onClick={toggleConfirmPasswordVisibility}
              />
            </div>
          </>
        )}
        {error && <p className="error-message-login-settings">{error}</p>}
        {(isEditing || showPasswordFields) && (
          <button className="button-login-settings" onClick={handleSaveProfile}>
            Guardar Cambios
          </button>
        )}
      </div>
    </div>
  );
};

export default Profile;
