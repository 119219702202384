import React, { useState, useEffect, useCallback } from 'react';
import logoImage from "../assets/darpha_logo_digital.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { GoogleLogin } from '@react-oauth/google';
import "./styles/Login.css"

const Home = () => {
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleLogin = async () => {
    if (!id || !password) {
      setError('Por favor, completa todos los campos');
      return;
    }
    
    if (!validateEmail(id)) {
      setError('Por favor, ingresa un correo electrónico válido');
      return;
    }
  
    try {
      const response = await axios.post('https://api-darpha-production.up.railway.app/api/login', {
        email: id,
        password
      });
  
      const { token } = response.data;
      const decodedToken = jwtDecode(token);
      const userRole = decodedToken.idrol;
      const userId = decodedToken.idusuario;
      const userName = decodedToken.nombreusuario;

      if (rememberMe === true) {
        localStorage.setItem('token', token);
        localStorage.setItem('userId', userId);
        localStorage.setItem('userName', userName);
        localStorage.setItem('userRole', userRole);
        console.log('ID Usuario guardado en localStorage:', userId);
    } else {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('userName', userName);
        sessionStorage.setItem('userRole', userRole);
        console.log('ID Usuario guardado en sessionStorage:', userId);
    }
    

      navigate('/bienvenida');
    } catch (error) {
      console.error('Error en el login:', error);
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('Error al iniciar sesión. Inténtalo de nuevo.');
      }
    }
  };

  const handleGoogleSuccess = async (response) => {
    try {
      const decodedToken = jwtDecode(response.credential);
      const { email, name } = decodedToken;
  
      const res = await axios.post('https://api-darpha-production.up.railway.app/api/google-login', {
        token: response.credential,
        email,
        nombre: name
      });
      
      const { token } = res.data;

      const decodedTokenApi = jwtDecode(token);
      const userRole = decodedTokenApi.idrol;
      const userId = decodedTokenApi.idusuario;
      const userName = decodedTokenApi.nombreusuario;

      if (rememberMe === true) {
        localStorage.setItem('token', token);
        localStorage.setItem('userId', userId);
        localStorage.setItem('userName', userName)
        localStorage.setItem('userRole', userRole);
        localStorage.setItem('rememberMe', true);
        console.log('ID Usuario guardado en localStorage:', userId);
    } else {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('userName', userName);
        sessionStorage.setItem('userRole', userRole);
        console.log('ID Usuario guardado en sessionStorage:', userId);
    }
    
  
    navigate('/bienvenida');
    } catch (error) {
      console.error('Error en el login con Google:', error);
      if (error.res && error.res.data && error.res.data.message) {
        setError(error.res.data.message);
      } else {
        setError('Error al iniciar sesión. Inténtalo de nuevo.');
      }
    }
  };

  const handleGoogleFailure = (response) => {
    console.log("Google login failed: ", response);
  };

  const refreshToken = useCallback(async () => {
    try {
      let token = localStorage.getItem('token');
      if (!token) {
        token = sessionStorage.getItem('token');
      }
      const { data } = await axios.post('https://api-darpha-production.up.railway.app/api/refresh-token', {
        token: token,
      });
      const rememberMe = localStorage.getItem('rememberMe');
      if (rememberMe === 'true') {
        localStorage.setItem('token', data.token);
      } else {
        sessionStorage.setItem('token', data.token);
      }
    } catch (error) {
      console.log("Error al refrescar el token:", error);
      // Redirige al login si el token no se puede renovar
      navigate('/');
    }
  }, [navigate]);
  
  useEffect(() => {
    let token = localStorage.getItem('token');
    if (!token) {
      token = sessionStorage.getItem('token');
    }

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const userRole = decodedToken.idrol;
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp > currentTime) {
          switch (userRole) {
            case 1:
              navigate('/home');
              break;
            case 2:
              navigate('/user');
              break;
            case 3:
              navigate('/welcome');
              break;
            case 4:
              navigate('/welcome');
              break;
            default:
              navigate('/');
          }
        } else if (decodedToken.exp <= currentTime) {
          refreshToken();
        } else if (decodedToken.exp < currentTime + 600) {
          refreshToken();
        }
      } catch (error) {
        console.error('Error al decodificar el token', error);
      }
    }
  }, [navigate, refreshToken]);  // Aquí agregamos refreshToken como dependencia
  
  

  return (
    <div className="login-container">
      <img className="login-logo" src={logoImage} alt="Logo" />
      <h1 className="login-title">Inicia Sesión</h1>
      <p className="login-subtitle">Ingresa tu ID y Contraseña</p>
      <input
        className="login-input"
        type="text"
        placeholder="ID"
        value={id}
        onChange={(e) => setId(e.target.value)}
      />
      <div className="login-password-container">
        <input
          className="login-password-input"
          type={showPassword ? 'text' : 'password'}
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <FontAwesomeIcon
          className="login-toggle-password-icon"
          icon={showPassword ? faEyeSlash : faEye}
          onClick={togglePasswordVisibility}
        />
      </div>
      {error && <p className="login-error-message">{error}</p>}

      <div className="remember-me-container">
        <input 
          type="checkbox" 
          checked={rememberMe} 
          onChange={(e) => setRememberMe(e.target.checked)} 
        />
        <label>Recordarme</label>
      </div>
      
      <div className="login-button-container">
        <button className="login-button" onClick={handleLogin}>Ingresar</button>
      </div>

      <p className="login-signup-text">
        ¿No tienes cuenta aún? <span className="login-signup-link" onClick={() => navigate("/signup")}>Regístrate</span>
      </p>

      <div className="login-divider">
        <span>or</span>
      </div>
      
      <div className="login-google-container">
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleFailure}
          useOneTap
        />
      </div>

      <p className="login-signup-text">
      © 2024 Darpha. Todos los derechos reservados.
      </p>
    </div>
  );
};

export default Home;