import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Corregido: Importar correctamente jwtDecode
import { isDesktop, isMobile } from "react-device-detect";
import Sidebar from "../../Global/components/Sidebar.js"; 
import BottomNavBar from '../../Global/components/BottomNavBar.js'; 
import "./styles/Equipment.css";

function Equipment(){
    const [isCel, setIsCel] = useState(false);
    const handleResize = () => {
        if (window.innerWidth < 768) {
          setIsCel(true);
        } else {
          setIsCel(false);
        }
      };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
      
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    let layout;
    if (isDesktop) {
        layout = <Sidebar />;
    } else if (isMobile) {
        layout = <BottomNavBar />;
    }

    return(
      <div className="container-main-equipment">
        {layout} 
        <div className="container-equipment">
            <h1 className="title-container-equipment">Equipo de Instalación</h1>
            <div className="">

            </div>
        </div>
      </div>
    );

}

export default Equipment;