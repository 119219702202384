import React, { useEffect, useState } from "react";
import "./styles/Maintenance.css";
import Sidebar from "../../Global/components/Sidebar.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BottomNavBar from '../../Global/components/BottomNavBar.js'; // Importamos BottomNavBar
import { isDesktop, isMobile } from "react-device-detect"; // Importamos isMobile y isDesktop

function Maintenance() {
  const [plants, setPlants] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchPlants() {
      try {
        // Obtener el token del localStorage
        let token = localStorage.getItem('token');
        
        // Si no hay token, obtenerlo de sessionStorage
        if (!token) {
          token = sessionStorage.getItem('token');
        }

        // Configurar las cabeceras de la solicitud
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };

        // Realizar la solicitud con el token en las cabeceras
        const response = await axios.get(
          "https://api-darpha-production.up.railway.app/api/plantas",
          config
        );
        setPlants(response.data);
      } catch (error) {
        console.error("Error fetching plants:", error);
        // Manejo de errores, podrías redirigir al usuario o mostrar un mensaje
        if (error.response && error.response.status === 403) {
          navigate('/'); // Redirige si el acceso es denegado
        }
      }
    }

    fetchPlants();
  }, [navigate]);


  let layout;

  // Verificamos si es un dispositivo de escritorio o móvil
  if (isDesktop) {
    layout = <Sidebar />;
  } else if (isMobile) {
    layout = <BottomNavBar />;
  }

  return (
    <div className="background-maintenance">
      {layout} {/* Aqui se muestra Sidebar o BottomNavBar dependiendo el dispositivo */}
      <div className="container-plants">
        <div className="maintenance-container">
          <h3>PLANTAS</h3>
          {isDesktop && (
            <>
              <div className="maintenance-items">
              {plants.map((plant, index) => (
                <button
                  className="button-mantenimiento"
                  key={index}
                  onClick={() =>
                    navigate(`/maintenance/${plant.nombreplanta.toLowerCase()}`)
                  }
                >
                  <div className="button-content">
                    <div
                      className="button-image"
                      style={{
                        backgroundImage: `url(${plant.image})`,
                        width: isDesktop ? "60%" : "20px",
                        height: isDesktop ? "60%" : "20px",
                        marginTop: "0",
                        marginLeft: "auto",
                        marginRight: "auto",
                        objectFit: "cover",
                      }}
                    ></div>
                    <span className="button-text">{plant.nombreplanta}</span>
                  </div>
                </button>
              ))}
            </div>
            </>
          )}

          {isMobile && (
            <>
              <div className="maintenance-items">
              {plants.map((plant, index) => (
                <button
                  className="button-mantenimiento"
                  key={index}
                  onClick={() =>
                    navigate(`/maintenance/${plant.nombreplanta.toLowerCase()}`)
                  }
                >
                  <div className="button-content">
                    <div
                      className="button-image"
                      style={{
                        backgroundImage: `url(${plant.image})`,
                        width: "50px",
                        height:"50px",
                        marginTop: "0",
                        marginLeft: "auto",
                        marginRight: "auto",
                        objectFit: "cover",
                      }}
                    ></div>
                    <span className="button-text">{plant.nombreplanta}</span>
                  </div>
                </button>
              ))}
            </div>
            </>
          )}
          
        </div>
      </div>
    </div>
  );
}

export default Maintenance;
