import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles/WidgetUsuarios.css";
import { useNavigate } from "react-router-dom";
import { isDesktop } from "react-device-detect";

function WidgetUsers() {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState({});
  const [selectedRol, setSelectedRol] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const navigate = useNavigate();
  const [plantas, setPlantas] = useState({});

const fetchPlantas = async () => {
  try {
    let token = localStorage.getItem("token") || sessionStorage.getItem("token");

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const plantasResponse = await axios.get(
      "https://api-darpha-production.up.railway.app/api/plantas", // Asegúrate de tener este endpoint en tu API
      config
    );

    const plantasData = {};
    plantasResponse.data.forEach((planta) => {
      plantasData[planta.idplanta] = planta.nombreplanta; // Mapea idplanta al nombreplanta
    });
    setPlantas(plantasData);
  } catch (error) {
    console.error("Error fetching plantas:", error);
  }
};


  const fetchUsers = async () => {
    try {
      let token = localStorage.getItem("token");
      if (!token) {
        token = sessionStorage.getItem("token");
      }
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await axios.get(
        "https://api-darpha-production.up.railway.app/api/users",
        config
      );
      // Inicializamos el campo 'selectedRol' para cada usuario
      const usersWithRoles = response.data.map((user) => ({
        ...user,
        selectedRol: user.idrol, // Cada usuario empieza con su rol actual
      }));
      setUsers(usersWithRoles);

      const rolesResponse = await axios.get(
        `https://api-darpha-production.up.railway.app/api/roles`,
        config
      );
      const rolesData = {};
      rolesResponse.data.forEach((rol) => {
        rolesData[rol.idrol] = rol.nombrerol;
      });
      setRoles(rolesData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchPlantas();
  }, []);

  useEffect(() => {
    if (selectedRol) {
      const filtered = users.filter(
        (user) => user.selectedRol === parseInt(selectedRol)
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(users);
    }
  }, [selectedRol, users]);

  const capitalizeWords = (str) => {
    if (!str) return "";
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleRoleChange = async (user) => {
    const nuevoRol = user.selectedRol;

    if (!nuevoRol || nuevoRol === user.idrol) return; // No se realiza petición si no hay cambio

    try {
      const token = localStorage.getItem("token");
      const config = { headers: { Authorization: `Bearer ${token}` } };

      const response = await axios.put(
        `https://api-darpha-production.up.railway.app/api/users/${user.idusuario}`,
        { idusuario: user.iduser, idrol: nuevoRol },
        config
      );

      if (response.status === 200) {
        console.log(`Rol de ${user.nombreusuario} actualizado a ${roles[nuevoRol]}`);
        fetchUsers(); // Refrescar los datos después de actualizar
      }
    } catch (error) {
      console.error("Error actualizando el rol del usuario:", error);
    }
  };

  const handleRoleSelectChange = (userId, newRoleId) => {
    // Actualizamos solo el 'selectedRol' del usuario que cambió
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.idusuario === userId
          ? { ...user, selectedRol: parseInt(newRoleId) }
          : user
      )
    );
  };

  return (
    <div className="widget-usuarios">
      <div className="usuarios-filters-container">
        <div className="usuarios-filter">
          <select
            id="rol"
            value={selectedRol}
            onChange={(e) => setSelectedRol(e.target.value)}
          >
            <option value="">Todos las roles</option>
            {Object.entries(roles).map(([id, nombre]) => (
              <option key={id} value={id}>
                {nombre}
              </option>
            ))}
          </select>
        </div>

        <div className="add-button">
          <button onClick={() => navigate("/adduser")}>
          {isDesktop ? (
                                <>
                                <i class="fa-solid fa-plus"></i>
                                Añadir Usuario
                                </>
                                ) 
                                : 
                                <i class="fa-solid fa-plus"></i>}
          </button>
        </div>
      </div>

      <div className="usuarios-table-container">
        <table className="usuarios-table">
          <thead>
            <tr>
              <th className="th">Nombre User</th>
              <th className="th">Email</th>
              <th className="th">Empresa / Planta</th>
              <th className="th">Rol</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.idusuario} className="table-row">
                <td className="td">{capitalizeWords(user.nombreusuario) || "-"}</td>
                <td className="td">{user.correoelectronico || "-"}</td>
                <td className="td">{plantas[user.idplanta] || "Darpha Fire Solutions"}</td>
                <td className="td-buttons">
                  <select
                    value={user.selectedRol} // Valor individual por usuario
                    onChange={(e) =>
                      handleRoleSelectChange(user.idusuario, e.target.value)
                    } // Cambia solo el rol del usuario
                  >
                    {Object.entries(roles).map(([id, nombre]) => (
                      <option key={id} value={id}>
                        {nombre}
                      </option>
                    ))}
                  </select>
                  {user.selectedRol !== user.idrol && ( // Mostrar solo si ha habido un cambio
                    <button
                      className="update-button"
                      onClick={() => handleRoleChange(user)} // Actualiza solo al hacer clic
                    >
                      <i className="fa-solid fa-arrows-rotate"></i>
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WidgetUsers;
