import React from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import './Sidebar.css';
import logo from '../../assets/darpha_logo_digital.png';
import { googleLogout } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Usamos useLocation para obtener la ubicación actual

  const getRoleFromToken = () => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (!token) {
      return null;
    }

    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.idrol;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };

  const getUsernameFromToken = () => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (!token) {
      return null;
    }

    try {
      const decodedToken = jwtDecode(token);
      const fullName = decodedToken.nombreusuario;
      return fullName.split(' ')[0]; // Extrae y retorna solo el primer nombre
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };

  const handleHomeClick = () => {
    const idrol = getRoleFromToken();
    if (idrol === 1) {
      navigate('/homeadmin');
    } else if (idrol === 2) {
      navigate('/homeinspector');
    } else if (idrol === 3) {
      navigate('/homesupervisor');
    } else {
      navigate('/');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    localStorage.removeItem('userId');
    sessionStorage.removeItem('userId');
    googleLogout();
    navigate('/');
  };

  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <img src={logo} alt="Darpha Logo" />
      </div>
      <div className="sidebar-username">{getUsernameFromToken()}</div>
      <div className="sidebar-menu">
        <div
          onClick={handleHomeClick}
          className={`menu-items ${location.pathname === '/homeadmin' ? 'active' : ''}`}
        >
          <i className="fas fa-home"></i>
          <span>Home</span>
        </div>
        <NavLink
          to="/profile"
          className={`menu-items ${location.pathname === '/profile' ? 'active' : ''}`}
        >
          <i className="fas fa-user"></i>
          <span>Profile</span>
        </NavLink>
        <NavLink
          to="/addplanta"
          className={`menu-items ${location.pathname === '/addplanta' ? 'active' : ''}`}
        >
          <i className="fas fa-user"></i>
          Add Planta
        </NavLink>
        <div onClick={handleLogout} className="menu-items">
          <i className="fa-solid fa-right-from-bracket"></i>
          <span>Logout</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
