import React, { useState } from 'react';
import axios from 'axios';

function AddPlantaForm() {
  const [image, setImage] = useState(null);
  const [nombreplanta, setnombreplanta] = useState('');
  const [direccion, setDireccion] = useState('');
  const [representante, setRepresentante] = useState('');

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', image);
    formData.append('nombreplanta', nombreplanta);
    formData.append('direccion', direccion);
    formData.append('representante', representante);

    try {
      // Obtener el token del localStorage o sessionStorage
      let token = localStorage.getItem('token') || sessionStorage.getItem('token');

      const response = await axios.post('https://api-darpha-production.up.railway.app/api/plantas', formData, {
        headers: { 
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}` // Agregar el token aquí
        }
      });
      console.log(response.data);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" placeholder="Nombre de la Planta" onChange={(e) => setnombreplanta(e.target.value)} required />
      <input type="text" placeholder="Dirección" onChange={(e) => setDireccion(e.target.value)} />
      <input type="text" placeholder="Representante" onChange={(e) => setRepresentante(e.target.value)} />
      <input type="file" accept="image/*" onChange={handleImageChange} required />
      <button type="submit">Subir Imagen y Crear Planta</button>
    </form>
  );
}

export default AddPlantaForm;
