// src/pages/Home.js
import React from "react";
import { useParams, Link } from "react-router-dom";
import "./styles/MaintenancePlants.css";
import Reporte from "../../assets/ReportesI.jpg";
import ReporteII from "../../assets/ReporteII.jpeg";
import Sidebar from '../../Global/components/Sidebar.js';
import BottomNavBar from '../../Global/components/BottomNavBar.js'; // Importamos BottomNavBar
import { isDesktop, isMobile } from "react-device-detect"; 

function MaintenancePlants() {
  // Obtener el nombre de la planta de los parámetros de la URL
  const { planta } = useParams(); 

  let layout;
    // Verificamos si es un dispositivo de escritorio o móvil
    if (isDesktop) {
      layout = <Sidebar />;
    } else if (isMobile) {
      layout = <BottomNavBar />;
    }
  

  return (
    <div className="page">
      {layout} {/* Aqui se mostrará Sidebar o BottomNavBar dependiendo del dispositivo */}
      <div className="home">
        <div className="container">
          <h3 className="h3-absolute">Estás en la planta {planta.toUpperCase()}</h3> {/* Mostrar el nombre de la planta */}
          <h2 className="h2-absolute">¿Qué vas a hacer?</h2>
          <div className="center">
            <Link to={`/maintenance/${planta || 'desconocida'}/machinetypes`} className="article-card-link">
              <div className="article-card">
                <div className="content">
                  <p className="title">Realizar Reporte</p>
                </div>
                <img src={Reporte} alt="article-cover" />
              </div>
            </Link>
            <Link to="/inventory" className="article-card-link">
              <div className="article-card">
                <div className="content">
                  <p className="title">Ver Reportes</p>
                </div>
                <img src={ReporteII} alt="article-cover" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaintenancePlants;
