// src/pages/MachineTypes.js
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "./styles/MachineTypes.css";
import Sidebar from './Global/components/Sidebar.js';
import BottomNavBar from './Global/components/BottomNavBar.js'; // Importamos BottomNavBar
import { isDesktop, isMobile } from "react-device-detect"; 

function MachineTypes() {
  const { planta } = useParams(); // Obtén el parámetro de la URL
  const [machineTypes, setMachineTypes] = useState([]);

  useEffect(() => {
    const fetchMachineTypes = async () => {
      try {
        // Obtener el token del localStorage
        let token = localStorage.getItem('token');
        
        // Si no hay token, obtenerlo de sessionStorage
        if (!token) {
          token = sessionStorage.getItem('token');
        }
        // Configurar las cabeceras de la solicitud
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };

        const response = await axios.get(
          "https://api-darpha-production.up.railway.app/api/tiposmaquina", config
        );
        setMachineTypes(response.data);
      } catch (error) {
        console.error("Error fetching machine types:", error);
      }
    };

    fetchMachineTypes();
  }, []);

  let layout;
  // Verificamos si es un dispositivo de escritorio o móvil
  if (isDesktop) {
    layout = <Sidebar />;
  } else if (isMobile) {
    layout = <BottomNavBar />;
  }

  return (
    <div className="machine-types-page">
      {layout} {/* Aqui se mostrará Sidebar o BottomNavBar dependiendo del dispositivo */}
      <div className="machine-types-container">
        <h3>{planta.toUpperCase()}</h3>
        <div className="machine-types-list">
          {machineTypes.map((type) => (
            <div key={type.idtipomaquina} className="machine-type-item">
              <Link
                to={`/maintenance/${planta}/machine/${type.idtipomaquina}`}
              >
                {type.nombretipo}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MachineTypes;
