import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Corregido: Importar correctamente jwtDecode
import './styles/WidgetTools.css';
import { isDesktop } from "react-device-detect";
function Tools() {
    const [tools, setTools] = useState([]); 
    const [newTool, setNewTool] = useState({ nombre: "", descripcion: "" }); 
    const navigate = useNavigate(); 


    // Función para obtener el nombre del usuario desde el token
    const getUsernameFromToken = () => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        if (!token) {
            return null; // Si no hay token, devolvemos null
        }

        try {
            // Decodificar el token y extraer el nombre del usuario
            const decodedToken = jwtDecode(token);
            return decodedToken.nombreusuario; // Asegúrate de que el token tenga el campo nombreusuario
        } catch (error) {
            console.error('Error decoding token:', error);
            return null;
        }
    };

    useEffect(() => {
        const fetchTools = async () => {
            try {
                // Obtener el token del localStorage
                let token = localStorage.getItem('token') || sessionStorage.getItem('token');
                
                // Si no hay token, obtenerlo de sessionStorage
                if (!token) {
                token = sessionStorage.getItem('token');
                }
                const config = { headers: { Authorization: `Bearer ${token}` } };
                const response = await axios.get('https://api-darpha-production.up.railway.app/api/herramientas', config);
                setTools(response.data);
            } catch (error) {
                console.error('Error fetching tools:', error);
                if (error.response && error.response.status === 403) {
                    navigate('/');
                }
            } 
        };

        fetchTools();
    }, [navigate]);

    const handleAddTool = async (e) => {
        e.preventDefault();
        try {
            let token = localStorage.getItem('token');
            if (!token) {
                token = sessionStorage.getItem('token');
            }
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const response = await axios.post('https://api-darpha-production.up.railway.app/api/herramientas', newTool, config);
            setTools([...tools, response.data]);
            setNewTool({ nombre: "", descripcion: "" });
        } catch (error) {
            console.error('Error adding tool:', error);
        }
    };

    const handleDeleteTool = async (id) => {
        try {
            let token = localStorage.getItem('token');
            if (!token) {
                token = sessionStorage.getItem('token');
            }
            const config = { headers: { Authorization: `Bearer ${token}` } };
            await axios.delete(`https://api-darpha-production.up.railway.app/api/herramientas/${id}`, config);
            setTools(tools.filter(tool => tool.id !== id)); 
        } catch (error) {
            console.error('Error deleting tool:', error);
        }
    };

    const handleBorrowTool = async (tool) => {
        try {
            let usuarioActual = getUsernameFromToken();
            let token = localStorage.getItem('token');
            if (!token) {
                token = sessionStorage.getItem('token');
            }
            const config = { headers: { Authorization: `Bearer ${token}` } };
    
            // Enviamos el usuario actual en el cuerpo de la petición
            const response = await axios.put(
                `https://api-darpha-production.up.railway.app/api/herramientas/prestar/${tool.id}`,
                { usuario_actual: usuarioActual }, // Aquí incluimos el usuario actual
                config
            );
    
            // Actualizar la fecha de préstamo y marcar como no disponible
            const updatedTool = { ...tool, fecha_prestamo: response.data.fecha_prestamo, disponible: false, usuario_actual: usuarioActual };
            setTools(tools.map(t => t.id === tool.id ? updatedTool : t));
        } catch (error) {
            console.log(getUsernameFromToken());
            console.error('Error prestando herramienta:', error);
        }
    };
    

    const handleReturnTool = async (tool) => {
        try {
            let ultimoUsuario = getUsernameFromToken();
            const token = localStorage.getItem('token');
            const config = { headers: { Authorization: `Bearer ${token}` } };
            await axios.put(
                `https://api-darpha-production.up.railway.app/api/herramientas/devolver/${tool.id}`,
                {ultimo_usuario: ultimoUsuario}, // Aquí incluimos el usuario actual
                config
            );
            
            // Actualizar la herramienta como disponible nuevamente
            const returnedTool = { ...tool, disponible: true, fecha_prestamo: null, ultimo_usuario: ultimoUsuario };
            setTools(tools.map(t => t.id === tool.id ? returnedTool : t));
    
        } catch (error) {
            console.error('Error devolviendo herramienta:', error);
        }
    };

    // Filtrar herramientas disponibles y prestadas
    const availableTools = tools.filter(tool => tool.disponible === true);
    const borrowedTools = tools.filter(tool => tool.disponible === false);


    return (
            <div className="widget-herramientas">
                <div className="section-herrameintasdisponibles">
                        <h2>Herramientas Disponibles</h2>
                    <div className="widget-add-tool-form" onSubmit={handleAddTool}>
                        <div className="input-container">
                        <input
                            type="text"
                            placeholder="Herramienta"
                            value={newTool.nombre}
                            onChange={(e) => setNewTool({ ...newTool, nombre: e.target.value })}
                            required
                        />
                        </div>
                        <div className="input-container">
                        <input
                            type="text"
                            placeholder="Descripción"
                            value={newTool.descripcion}
                            onChange={(e) => setNewTool({ ...newTool, descripcion: e.target.value })}
                            required
                        />
                        </div>
                        <div className="add-button">
                        <button type="add-tools" onClick={handleAddTool}>
                            {isDesktop ? (
                                <>
                                <i class="fa-solid fa-plus"></i>
                                Añadir Herramienta
                                </>
                                ) 
                                : 
                                <i class="fa-solid fa-plus"></i>}
                        </button>
                        </div>
                    </div>

                    <div className="widget-herramientas-table-container">
                        <table className="table-herramientas">
                            <thead>
                                <tr>
                                    <th className="th-herramientas">Id</th>
                                    <th className="th-herramientas">Nombre</th>
                                    <th className="th-herramientas">Descripción</th>
                                    <th className="th-herramientas">Ultimo Uso</th>
                                    <th className="th-herramientas">Usuario</th>
                                    <th className="th-herramientas">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {availableTools.map((tool) => (
                                    <tr key={tool.id} className="table-row-herramientas">
                                        <td className="td-herramientas">{tool.id}</td>
                                        <td className="td-herramientas">{tool.nombre}</td>
                                        <td className="td-herramientas">{tool.descripcion}</td>
                                        <td className="td-herramientas">{tool.fecha_retorno || 'N/A'}</td>
                                        <td className="td-herramientas">{tool.ultimo_usuario || 'N/A'}</td>
                                        <td className="td-buttons-herramientas">
                                            {/* Botón para eliminar herramienta */}
                                            <button className="delete-tool-button" onClick={() => handleDeleteTool(tool.id)}>
                                                <i class="fa-solid fa-trash"></i>
                                                <p>Eliminar</p>
                                            </button>
                                            <button 
                                                className="take-button" 
                                                onClick={() => handleBorrowTool(tool)}
                                            >
                                                <i class="fa-regular fa-hand"></i>
                                                <p>Tomar</p>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="section-herramientasprestadas">
                        <h2>Herramientas Prestadas</h2>
                
                    <div className="widget-herramientas-table-container">
                    <table className="table-herramientas">
                        <thead>
                            <tr>
                                <th className="th-herramientas">Id</th>
                                <th className="th-herramientas">Nombre</th>
                                <th className="th-herramientas">Descripción</th>
                                <th className="th-herramientas">Usuario</th>
                                <th className="th-herramientas">Fecha de préstamo</th>
                                <th className="th-herramientas">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {borrowedTools.map((tool) => (
                                <tr key={tool.id} className="table-row-herramientas">
                                    <td className="td-herramientas">{tool.id}</td>
                                    <td className="td-herramientas">{tool.nombre}</td>
                                    <td className="td-herramientas">{tool.descripcion}</td>
                                    <td className="td-herramientas">{tool.usuario_actual || 'N/A'}</td>
                                    <td className="td-herramientas">{tool.fecha_prestamo || 'N/A'}</td>
                                    <td className="td-herramientas">
                                        {/* Botón para devolver herramienta */}
                                        <button 
                                            className="return-button" 
                                            onClick={() => handleReturnTool(tool)}
                                        >
                                            <i class="fa-solid fa-rotate-left"></i>
                                            <p>Devolver</p>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>

            </div>
    );
}

export default Tools;
