import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Asegúrate de importar jwtDecode correctamente

const ProtectedRoute = ({ element: Component, requiredRole, ...rest }) => {
  // Obtener el token del localStorage
  let token = localStorage.getItem('token');
        
  // Si no hay token, obtenerlo de sessionStorage
  if (!token) {
    token = sessionStorage.getItem('token');
  }
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = () => {
      // Si el token no está presente cuando el usuario intenta retroceder en el historial
      if (!localStorage.getItem('token')) {
        navigate('/'); // Redirigir al login
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  if (!token) return <Navigate to="/" state={{ from: location }} />; // Redirige si no hay token

  try {
    const decodedToken = jwtDecode(token); // Decodifica el token
    if (!requiredRole.includes(decodedToken.idrol)) {
      return <Navigate to="/" state={{ from: location }} />; // Redirige si el rol no está permitido
    }
  } catch (error) {
    return <Navigate to="/" state={{ from: location }} />; // Redirige si hay error al decodificar el token
  }

  return <Component {...rest} />;
};

export default ProtectedRoute;
