import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles/WidgetMaquinas.css";
import { useNavigate } from "react-router-dom";
import { isDesktop } from "react-device-detect";

function WidgetMaquinas() {
  const [machine, setMachines] = useState([]);
  const [tipoMaquinas, setTipoMaquinas] = useState({});
  const [plantas, setPlantas] = useState({});
  const [selectedPlanta, setSelectedPlanta] = useState("");
  const [selectedTipo, setSelectedTipo] = useState("");
  const [filteredMachines, setFilteredMachines] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState({});
  const navigate = useNavigate();
  const [filteredTipoMaquinas, setFilteredTipoMaquinas] = useState({});


  useEffect(() => {
    const fetchMachines = async () => {
      try {
        let token = localStorage.getItem("token");
        if (!token) {
          token = sessionStorage.getItem("token");
        }

        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };

        const response = await axios.get(
          "https://api-darpha-production.up.railway.app/api/maquinas",
          config
        );
        setMachines(response.data);

        const tiposResponse = await axios.get(
          `https://api-darpha-production.up.railway.app/api/tiposmaquina`,
          config
        );
        const tiposData = {};
        tiposResponse.data.forEach((tipo) => {
          tiposData[tipo.idtipomaquina] = tipo.nombretipo;
        });
        setTipoMaquinas(tiposData);
        console.log("Fetched Tipo Maquinas:", tiposData); // Verifica los tipos de máquinas obtenidos

        const plantasResponse = await axios.get(
          `https://api-darpha-production.up.railway.app/api/plantas`,
          config
        );
        const plantasData = {};
        plantasResponse.data.forEach((planta) => {
          plantasData[planta.idplanta] = planta.nombreplanta;
        });
        setPlantas(plantasData);
        console.log("Fetched Plantas:", plantasData); // Verifica las plantas obtenidas
      } catch (error) {
        console.error("Error fetching machines or types:", error);
      }
    };

    fetchMachines();
  }, []);

  useEffect(() => {
    let filtered = machine;

    if (selectedPlanta) {
      filtered = filtered.filter(
        (mach) => mach.idplanta === parseInt(selectedPlanta)
      );
    }

    if (selectedTipo) {
      filtered = filtered.filter(
        (mach) => mach.idtipomaquina === parseInt(selectedTipo)
      );
    }

    setFilteredMachines(filtered);
    console.log("Filtered Machines:", filtered); // Verifica las máquinas filtradas

    // Determinar visibilidad de las columnas
    const columnsVisibility = {
      idmaquina: filtered.some((mach) => mach.idmaquina !== null),
      idtipomaquina: filtered.some((mach) => tipoMaquinas[mach.idtipomaquina] !== null),
      idplanta: filtered.some((mach) => plantas[mach.idplanta] !== null),
      numero: filtered.some((mach) => mach.numero !== null),
      ubicacion: filtered.some((mach) => mach.ubicacion !== null),
      tipoespecifico: filtered.some((mach) => mach.tipoespecifico !== null),
      areaprotegida: filtered.some((mach) => mach.areaprotegida !== null),
      modulo: filtered.some((mach) => mach.modulo !== null),
      diametro: filtered.some((mach) => mach.diametro !== null),
      medidanominal: filtered.some((mach) => mach.medidanominal !== null),
      matrizcausaefecto: filtered.some((mach) => mach.matrizcausaefecto !== null),
    };

    setVisibleColumns(columnsVisibility);
  }, [selectedPlanta, selectedTipo, machine, tipoMaquinas, plantas]);

  useEffect(() => {
    if (selectedPlanta) {
      // Filtrar las máquinas por la planta seleccionada
      const filtered = machine.filter(
        (mach) => mach.idplanta === parseInt(selectedPlanta)
      );
  
      // Obtener solo los tipos de máquinas presentes en la planta seleccionada
      const tiposInPlanta = {};
      filtered.forEach((mach) => {
        if (tipoMaquinas[mach.idtipomaquina]) {
          tiposInPlanta[mach.idtipomaquina] = tipoMaquinas[mach.idtipomaquina];
        }
      });
  
      setFilteredTipoMaquinas(tiposInPlanta);
    } else {
      // Si no hay planta seleccionada, mostramos todos los tipos de máquinas
      setFilteredTipoMaquinas(tipoMaquinas);
    }
  }, [selectedPlanta, machine, tipoMaquinas]);
  

  return (
    <div className="widget-maquinas">
      <div className="filters-container">
        <div className="filter">
          <select
            id="planta"
            value={selectedPlanta}
            onChange={(e) => setSelectedPlanta(e.target.value)}
          >
            <option value="">Todas las plantas</option>
            {Object.entries(plantas).map(([id, nombre]) => (
              <option key={id} value={id}>
                {nombre}
              </option>
            ))}
          </select>
        </div>

        <div className="filter">
          <select
            id="tipoMaquina"
            value={selectedTipo}
            onChange={(e) => setSelectedTipo(e.target.value)}
          >
            <option value="">Todos los tipos</option>
            {Object.entries(filteredTipoMaquinas).map(([id, nombre]) => (
              <option key={id} value={id}>
                {nombre}
              </option>
            ))}
          </select>

        </div>

        <div className="add-button">
          <button onClick={() => navigate("/addmaquina")}>
          {isDesktop ? (
              <>
              <i class="fa-solid fa-plus"></i>
              Añadir Planta
              </>
              ) 
              : 
              <i class="fa-solid fa-plus"></i>}
          </button>
        </div>
      </div>

      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              {visibleColumns.idmaquina && <th className="th">Id Maquina</th>}
              {visibleColumns.idtipomaquina && (
                <th className="th">Tipo de Maquina</th>
              )}
              {visibleColumns.idplanta && <th className="th">Planta</th>}
              {visibleColumns.numero && <th className="th">#</th>}
              {visibleColumns.ubicacion && <th className="th">Ubicación</th>}
              {visibleColumns.tipoespecifico && (
                <th className="th">Tipo Específico</th>
              )}
              {visibleColumns.areaprotegida && (
                <th className="th">Área Protegida</th>
              )}
              {visibleColumns.modulo && <th className="th">Módulo</th>}
              {visibleColumns.diametro && <th className="th">Diámetro</th>}
              {visibleColumns.medidanominal && (
                <th className="th">Medida Nominal</th>
              )}
              {visibleColumns.matrizcausaefecto && (
                <th className="th">Matriz Causa Efecto</th>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredMachines.map((mach) => (
              <tr key={mach.idmaquina} className="table-row">
                {visibleColumns.idmaquina && (
                  <td className="td">{mach.idmaquina || "-"}</td>
                )}
                {visibleColumns.idtipomaquina && (
                  <td className="td">
                    {tipoMaquinas[mach.idtipomaquina] || "-"}
                  </td>
                )}
                {visibleColumns.idplanta && (
                  <td className="td">{plantas[mach.idplanta] || "-"}</td>
                )}
                {visibleColumns.numero && <td className="td">{mach.numero || "-"}</td>}
                {visibleColumns.ubicacion && (
                  <td className="td">{mach.ubicacion || "-"}</td>
                )}
                {visibleColumns.tipoespecifico && (
                  <td className="td">{mach.tipoespecifico || "-"}</td>
                )}
                {visibleColumns.areaprotegida && (
                  <td className="td">{mach.areaprotegida || "-"}</td>
                )}
                {visibleColumns.modulo && <td className="td">{mach.modulo || "-"}</td>}
                {visibleColumns.diametro && <td className="td">{mach.diametro || "-"}</td>}
                {visibleColumns.medidanominal && (
                  <td className="td">{mach.medidanominal || "-"}</td>
                )}
                {visibleColumns.matrizcausaefecto && (
                  <td className="td">{mach.matrizcausaefecto || "-"}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WidgetMaquinas;
