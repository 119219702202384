// src/components/ControlPanel/ControlPanel.js
import React, { useState, useEffect } from 'react';
import './styles/ControlPanelAdmin.css';
import Sidebar from '../../Global/components/Sidebar.js';
import BottomNavBar from '../../Global/components/BottomNavBar.js'; // Importamos BottomNavBar
import { isDesktop, isMobile } from "react-device-detect"; // Importamos isMobile y isDesktop
import WidgetMaquinas from './WidgetMaquinas';
import WidgetPlantas from './WidgetPlantas';
import WigdetUsers from './WidgetUsuarios';
import WidgetTools from './WidgetTools';
import WidgetInventory from './WidgetInventory.js';

const ControlPanelAdmin = () => {
  const [selectedOption, setSelectedOption] = useState('Maquinas'); // Estado para la pestaña seleccionada
  const [isCel, setIsCel] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsCel(true);
    } else {
      setIsCel(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  const renderWidget = () => {
    switch (selectedOption) {
      case 'Maquinas':
        return <WidgetMaquinas />;
      case 'Plantas':
        return <WidgetPlantas />;
      case 'Mantenimientos':
        return <div>Mantenimientos Widget Placeholder</div>;
      case 'Herramientas':
        return <WidgetTools />;
      case 'Usuarios':
        return <WigdetUsers />;
      case 'Inventario':
        return <WidgetInventory />;
      default:
        return null;
    }
  };

  let layout;
  // Verificamos si es un dispositivo de escritorio o móvil
  if (isDesktop && !isCel) {
    layout = <Sidebar />;
  } else if (isMobile || isCel) {
    layout = <BottomNavBar />;
  }

  return (
    <section className="control-panel-main">
      {layout} {/* Aqui se mostrará Sidebar o BottomNavBar dependiendo del dispositivo */}
      <div className="control-panel-box">
        {isDesktop && !isCel && (
          <>
          <ul className="options">
          <li 
            className={selectedOption === 'Maquinas' ? 'active' : ''} 
            onClick={() => setSelectedOption('Maquinas')}
          >
            Maquinas
          </li>
          <li 
            className={selectedOption === 'Plantas' ? 'active' : ''} 
            onClick={() => setSelectedOption('Plantas')}
          >
            Plantas
          </li>
          <li 
            className={selectedOption === 'Mantenimientos' ? 'active' : ''} 
            onClick={() => setSelectedOption('Mantenimientos')}
          >
            Mantenimientos
          </li>
          <li 
            className={selectedOption === 'Herramientas' ? 'active' : ''} 
            onClick={() => setSelectedOption('Herramientas')}
          >
            Herramientas
          </li>
          <li 
            className={selectedOption === 'Usuarios' ? 'active' : ''} 
            onClick={() => setSelectedOption('Usuarios')}
          >
            Usuarios
          </li>
          <li 
            className={selectedOption === 'Inventario' ? 'active' : ''} 
            onClick={() => setSelectedOption('Inventario')}
          >
            Inventario
          </li>
        </ul>
        <div className="course">
          <div className="box">
            {renderWidget()} {/* Renderiza el widget basado en la pestaña seleccionada */}
          </div>
        </div>
          </>
        )}

        {(isMobile || isCel) && (
          <>
          <div className="course">
          <div className="box">
            {renderWidget()} {/* Renderiza el widget basado en la pestaña seleccionada */}
          </div>
          </div>

          <ul className="options">
          <li 
            className={selectedOption === 'Maquinas' ? 'active' : ''} 
            onClick={() => setSelectedOption('Maquinas')}
          >
            Maquinas
          </li>
          <li 
            className={selectedOption === 'Plantas' ? 'active' : ''} 
            onClick={() => setSelectedOption('Plantas')}
          >
            Plantas
          </li>
          <li 
            className={selectedOption === 'Mantenimientos' ? 'active' : ''} 
            onClick={() => setSelectedOption('Mantenimientos')}
          >
            Mantenimientos
          </li>
          <li 
            className={selectedOption === 'Herramientas' ? 'active' : ''} 
            onClick={() => setSelectedOption('Herramientas')}
          >
            Usuarios
          </li>
          <li 
            className={selectedOption === 'Usuarios' ? 'active' : ''} 
            onClick={() => setSelectedOption('Usuarios')}
          >
            Usuarios
          </li>
          <li 
            className={selectedOption === 'Inventario' ? 'active' : ''} 
            onClick={() => setSelectedOption('Inventario')}
          >
            Inventario
          </li>
        </ul>
          </>
        )}
        
      </div>
    </section>
  );
};

export default ControlPanelAdmin;
