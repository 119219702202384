import React, { useState, useEffect } from 'react';
import './DefaultPage.css'; // Aquí agregarías los estilos correspondientes
import Sidebar from '../../Global/components/Sidebar';
import BottomNavBar from '../../Global/components/BottomNavBar.js'; // Importamos BottomNavBar
import { isDesktop, isMobile } from "react-device-detect"; // Importamos isMobile y isDesktop
import axios from 'axios'; // Asegúrate de tener Axios instalado para las peticiones a la API

const InfoPage = () => {
  const [formVisible, setFormVisible] = useState(false); // Controla la visibilidad del formulario
  const [selectedRole, setSelectedRole] = useState(''); // Controla el rol seleccionado
  const [nombre, setNombre] = useState(''); // Guarda el nombre completo
  const [planta, setPlanta] = useState(''); // Guarda la planta seleccionada (solo para Supervisor)
  const [plantas, setPlantas] = useState([]); // Guarda las plantas obtenidas de la base de datos

  // Función para obtener las plantas de la base de datos
  const fetchPlantas = async () => {
    try {
      // Obtener el token del localStorage
      let token = localStorage.getItem('token');
        
      // Si no hay token, obtenerlo de sessionStorage
      if (!token) {
        token = sessionStorage.getItem('token');
      }

      // Configurar las cabeceras de la solicitud
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      const response = await axios.get('https://api-darpha-production.up.railway.app/api/plantas', config); // Ajusta la URL de la API
      setPlantas(response.data); // Suponiendo que el resultado sea un array de plantas
    } catch (error) {
      console.error('Error obteniendo las plantas:', error);
    }
  };

  // Llamamos a fetchPlantas cuando el componente se monta
  useEffect(() => {
    if (selectedRole === 'Supervisor') {
      fetchPlantas();
    }
  }, [selectedRole]);

  const handleSolicitarAcceso = (role) => {
    setSelectedRole(role); // Actualiza el rol seleccionado
    setFormVisible(true); // Muestra el formulario
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Obtener el token del localStorage
    let token = localStorage.getItem('token');
        
    // Si no hay token, obtenerlo de sessionStorage
    if (!token) {
        token = sessionStorage.getItem('token');
    }

    // Obtener el idusuario (por ejemplo, desde localStorage o contexto)
    let idusuario = localStorage.getItem('userId') || sessionStorage.getItem('userId'); // Asegúrate de que el idusuario esté almacenado aquí

    // Configurar las cabeceras de la solicitud
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    
    const updatedData = {
        nombreusuario: nombre,
    };

    if (selectedRole === 'Supervisor') {
        updatedData.idplanta = planta; // Solo para el rol de Supervisor
    }

    // Depurar los valores que se enviarán
    console.log('ID Usuario:', idusuario);
    console.log('Datos a actualizar:', updatedData);

    try {
        // Aquí agregamos el idusuario en la URL
        await axios.put(`https://api-darpha-production.up.railway.app/api/users/${idusuario}`, 
          updatedData, 
          config
        ); // Ajusta la URL de la API
        alert('Datos actualizados correctamente');
        setFormVisible(false); // Oculta el formulario después de enviar
    } catch (error) {
        console.error('Error actualizando usuario:', error);
        alert('Hubo un problema actualizando los datos');
    }
};

  const handleCancel = () => {
    setFormVisible(false); // Oculta el formulario cuando se presiona el botón de cancelar
  };

  let layout;

  // Verificamos si es un dispositivo de escritorio o móvil
  if (isDesktop) {
    layout = <Sidebar />;
  } else if (isMobile) {
    layout = <BottomNavBar />;
  }

  return (
    <div className={`page ${formVisible ? 'form-active' : ''}`}>
      {layout} {/* Aqui se mostrará Sidebar o BottomNavBar dependiendo del dispositivo */}
      <div className="info-page">
        <h1>Bienvenido a Darpha Maintenance App</h1>
        <p>
          <strong>Objetivo:</strong> La Darpha Maintenance App está diseñada para
          digitalizar la creación y manejo de reportes de mantenimiento de equipos
          contra incendios.
        </p>
        
        <h2>¿Qué puedes hacer en la aplicación?</h2>
        <div className="roles-section">
          {/* Card para Administrador */}
          <div className="role">
            <h3>Administrador</h3>
            <ul>
              <li>Acceso a todos los reportes de todas las plantas</li>
              <li>Administración de usuarios</li>
              <li>Alta y baja de máquinas</li>
              <li>Generación de reportes</li>
              <li>Acceso a datos de maquinaria, empleados, plantas y reportes</li>
              <li>Control del estado de herramientas y equipo</li>
            </ul>
            {/* Botón para solicitar acceso al final de la sección */}
            <div className="roles-footer">
              <button className="solicitar-acceso-button" onClick={() => handleSolicitarAcceso('Administrador')}>
                Solicitar Acceso
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>

          {/* Card para Inspector */}
          <div className="role">
            <h3>Inspector</h3>
            <ul>
              <li>Creación de reportes en cualquier planta</li>
              <li>Consulta e impresión de reportes en PDF</li>
              <li>Solicitud de herramientas y equipo del inventario</li>
            </ul>
            {/* Botón para solicitar acceso al final de la sección */}
            <div className="roles-footer">
              <button className="solicitar-acceso-button" onClick={() => handleSolicitarAcceso('Inspector')}>
                Solicitar Acceso
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>

          {/* Card para Supervisor */}
          <div className="role">
            <h3>Supervisor</h3>
            <ul>
              <li>Acceso solo a reportes de su planta</li>
              <li>Firma de reportes como autorizado</li>
              <li>Consulta e impresión de reportes en PDF</li>
            </ul>
            {/* Botón para solicitar acceso al final de la sección */}
            <div className="roles-footer">
              <button className="solicitar-acceso-button" onClick={() => handleSolicitarAcceso('Supervisor')}>
                Solicitar Acceso
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>

          {formVisible && (
            <form className="form-container" onSubmit={handleSubmit}>
              {/* Formulario */}
              <h2>Solicitar acceso como {selectedRole}</h2>
              <label>
                Nombre completo:
                <input
                  type="text"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  required
                />
              </label>

              {selectedRole === 'Supervisor' && (
                <label>
                  Planta:
                  <select
                    value={planta}
                    onChange={(e) => setPlanta(e.target.value)}
                    required
                  >
                    <option value="">Selecciona una planta</option>
                    {plantas.map((planta) => (
                      <option key={planta.idplanta} value={planta.idplanta}>
                        {planta.nombreplanta}
                      </option>
                    ))}
                  </select>
                </label>
              )}

              <button className="submit-button" type="submit">
                Enviar
              <i class="fa-regular fa-paper-plane"></i>
              </button>
              <button className="cancel-button" type="button" onClick={handleCancel}>
              <i class="fa-solid fa-xmark"></i>
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoPage;
