// src/components/Welcome.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import './styles/Welcome.css';
import Logo from '../assets/darpha_logo_digital.png';

const Welcome = () => {
  const navigate = useNavigate();
  const [displayedName, setDisplayedName] = useState('');
  const [userName, setUserName] = useState('');

  const getUsernameFromToken = () => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (!token) {
      return null;
    }

    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.nombreusuario; // Asegúrate de que esto sea el campo correcto
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };

  useEffect(() => {
    const name = getUsernameFromToken();
    console.log('Username from token:', name); // Log para verificar el nombre extraído

    if (name) {
      const firstName = name.split(' ')[0]; // Extrae la primera palabra del nombre
      setUserName(firstName);
      typeWriter(firstName); // Llamar a la función de escritura con solo el primer nombre
    }

    // Redirigir después de 3 segundos a la página correspondiente
    const timer = setTimeout(() => {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      console.log('Token:', token); // Log para verificar el token

      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const role = decodedToken.idrol; // Obtén el rol directamente del token
          console.log('User role:', role); // Log para verificar el rol

          switch (role) {
            case 1:
              navigate('/homeadmin');
              break;
            case 2:
              navigate('/homeinspector');
              break;
            case 3:
            case 4:
              navigate('/welcome'); // Cambia esta ruta según tu lógica
              break;
            default:
              navigate('/');
          }
        } catch (error) {
          console.error('Error decoding token:', error);
          navigate('/'); // Si hay un error al decodificar, redirige a la página principal
        }
      } else {
        navigate('/'); // Si no hay token, redirige a la página principal
      }
    }, 2700); // Cambia el tiempo según sea necesario

    return () => clearTimeout(timer);
  }, [navigate]);

  const typeWriter = (name) => {
    let i = -1;
    const speed = 100; // Velocidad de escritura en milisegundos
  
    const typing = () => {
      if (i < name.length) {
        setDisplayedName(name.substring(0, i + 1)); // Actualiza el estado con la subcadena correcta
        console.log('Current displayed name:', name.substring(0, i + 1)); // Muestra el nombre actual
        i++;
        setTimeout(typing, speed);
      }
    };
  
    typing();
  };
  
  return (
    <div className="welcome-container">
      {/* <h1 className="welcome-message-title">Darpha Maintenance App</h1> */}
      <div className="welcome-logo-container">
        <img src={Logo} alt="Darpha Logo" className="welcome-logo" />
      </div>
      <div className="welcome-message-container">
        <span className="welcome-message">¡ Bienvenido, <span className="welcome-username">{displayedName}</span> !</span>
      </div>
      <p className="welcome-animation">Cargando...</p>
      {/* Aquí puedes agregar animaciones CSS si lo deseas */}
    </div>
  );
};

export default Welcome;
