import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
const Container = styled.div`
  padding: 40px 20px;
  max-width: 800px;
  margin: 40px auto;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;
  color: #343a40;
  text-align: center;
  margin-bottom: 30px;
`;

const Section = styled.section`
  margin-bottom: 25px;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: #495057;
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-size: 14px;
  color: #6c757d;
  line-height: 1.6;
`;

const TermsOfService = () => {
  return (
    <Container>
      <Title>Términos de Servicio</Title>
      <Section>
        <SectionTitle>Bienvenida</SectionTitle>
        <Text>
        Te damos la bienvenida a Darpha Maintenance App. Al utilizar nuestra aplicación, aceptas estos términos de servicio, los cuales regulan tu uso de la aplicación y los servicios proporcionados por Darpha Fire Solutions S.A. de C.V. Te recomendamos que los leas detenidamente.
        </Text>
      </Section>
      <Section>
        <SectionTitle>El Servicio</SectionTitle>
        <Text>
        Darpha Maintenance App es una plataforma que permite crear y administrar reportes de mantenimiento de manera digital. Nuestros usuarios incluyen trabajadores de Darpha Fire Solutions S.A. de C.V. y de empresas a las que se ofrecen servicios de mantenimiento de equipos contra incendios. A través de la app, los técnicos pueden realizar inspecciones, reportar mantenimientos y obtener firmas digitales de clientes.
        </Text>
      </Section>
      <Section>
        <SectionTitle>Uso del Servicio</SectionTitle>
        <Text>
        Para utilizar Darpha Maintenance App, debes crear una cuenta con información válida, como tu nombre y correo electrónico. La app recopila y utiliza tus datos personales de acuerdo con nuestra <Link to="/privacy-policy">Política de Privacidad</Link>. No está permitido crear cuentas falsas, hacerse pasar por otra persona o utilizar información incorrecta.
        </Text>
      </Section>
      <Section>
        <SectionTitle>Obligaciones del Usuario</SectionTitle>
        <Text>
        Al usar la app, te comprometes a no realizar actividades ilegales, fraudulentas o malintencionadas. Está prohibido el uso de la aplicación para fines que no estén relacionados con los servicios de mantenimiento proporcionados por Darpha Fire Solutions. También debes garantizar que los datos ingresados en la app, como firmas digitales y reportes de mantenimiento, sean verídicos.
        </Text>
      </Section>
      <Section>
        <SectionTitle>Seguridad</SectionTitle>
        <Text>
        Nos tomamos muy en serio la seguridad de nuestros usuarios. Implementamos medidas técnicas y organizativas para proteger tu información. Sin embargo, no podemos garantizar la seguridad absoluta de los datos almacenados o transmitidos a través de la aplicación. Te comprometes a mantener la confidencialidad de tu información de inicio de sesión.
        </Text>
      </Section>
      <Section>
        <SectionTitle>Propiedad Intelectual</SectionTitle>
        <Text>
        Todos los derechos sobre la aplicación, el software y los contenidos proporcionados en Darpha Maintenance App pertenecen a Darpha Fire Solutions S.A. de C.V. No se permite la modificación, distribución o copia de ningún contenido sin nuestro consentimiento previo.
        </Text>
      </Section>
      <Section>
        <SectionTitle>Modificaciones y Terminación del Servicio</SectionTitle>
        <Text>
        Nos reservamos el derecho de modificar, suspender o terminar el acceso a Darpha Maintenance App en cualquier momento y por cualquier motivo, incluido el incumplimiento de estos Términos de Servicio. Notificaremos cualquier cambio importante a través de la app o del correo electrónico proporcionado en tu cuenta.
        </Text>
      </Section>
      <Section>
        <SectionTitle>Responsabilidad</SectionTitle>
        <Text>
        Darpha Fire Solutions no será responsable por daños directos o indirectos que resulten del uso o la imposibilidad de usar la aplicación. Esto incluye pérdida de datos, interrupción del servicio, errores o cualquier otro inconveniente que pueda surgir.
        </Text>
      </Section>
      <Section>
        <SectionTitle>Ley Aplicable</SectionTitle>
        <Text>
        Estos Términos de Servicio se rigen por las leyes de México. Cualquier disputa relacionada con el uso de Darpha Maintenance App será resuelta en los tribunales competentes de Aguascalientes, México.
        </Text>
      </Section>
      <Section>
        <SectionTitle>Contacto</SectionTitle>
        <Text>
        Si tienes alguna pregunta sobre estos términos, puedes ponerte en contacto con nosotros en darphafiresolutions@gmail.com.
        </Text>
      </Section>
    </Container>
  );
};

export default TermsOfService;
