import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles/WidgetInventory.css";
import { useNavigate } from "react-router-dom";
import { isDesktop } from "react-device-detect";

function WidgetInventory() {
  const [equipo, setEquipos] = useState([]);
  const [tipoInstalacion, setTipoInstalacion] = useState({});
  const [grupoInstalacion, setGruposInstalacion] = useState({});
  const [medidaInstalacion, setMedidaInstalacion] = useState({});
  const [unidadMedida, setUnidadMedida] = useState({});

  // Estado para almacenar la selección de filtros
  const [selectedTipo, setSelectedTipo] = useState("");
  const [selectedGrupo, setSelectedGrupo] = useState("");
  const [selectedMedida, setSelectedMedida] = useState("");
  const [selectedUnidad, setSelectedUnidad] = useState(""); 
  const [selectedEstado, setSelectedEstado] = useState("");


  // Filtros
  const [filteredEquipos, setFilteredEquipos] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState({});
  const navigate = useNavigate();
  const [filteredTipoInstalacion, setFilteredTipoInstalacion] = useState({});
  const [filteredGrupoInstalacion, setFilteredGrupoInstalacion] = useState({});
  const [filteredMedidaInstalacion, setFilteredMedidaInstalacion] = useState({});
  const [filteredEstado, setFilteredEstado] = useState({});


  useEffect(() => {
    const fetchEquipos = async () => {
      try {
        let token = localStorage.getItem("token");
        if (!token) {
          token = sessionStorage.getItem("token");
        }

        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };

        const response = await axios.get(
          "https://api-darpha-production.up.railway.app/api/equipos",
          config
        );

        setEquipos(response.data);

        // Crear un objeto para almacenar los tipos de instalación
        const tiposResponse = await axios.get(
          `https://api-darpha-production.up.railway.app/api/tiposInstalacion`,
          config
        );

        const tiposData = {};
        tiposResponse.data.forEach((tipo) => {
          tiposData[tipo.idtipoinstalacion] = tipo.tipoinstalacion;
        });
        setTipoInstalacion(tiposData);
        console.log("Fetched Tipo Instalacion:", tiposData); // Verifica los tipos de instalación obtenidos

        // Crear un objeto para almacenar los grupos de instalación
        const gruposResponse = await axios.get(
          `https://api-darpha-production.up.railway.app/api/gruposInstalacion`,
          config
        );

        const gruposData = {};
        gruposResponse.data.forEach((grupo) => {
          gruposData[grupo.idgrupoinstalacion] = grupo.grupoinstalacion;
        });
        setGruposInstalacion(gruposData);
        console.log("Fetched Grupos:", gruposData); // Verifica las grupos obtenidas
        
        // Crear un objeto para almacenar las medidas de instalación
        const medidasResponse = await axios.get(
            `https://api-darpha-production.up.railway.app/api/medidasInstalacion`,
            config
          );

        const medidasData = {};
        medidasResponse.data.forEach((medida) => {
          medidasData[medida.idmedidainstalacion] = medida.medidainstalacion;
        })
        setMedidaInstalacion(medidasData);
        console.log("Fetched Medidas:", medidasData); // Verifica las medidas de instalación

        // Crear un objeto para almacenar las unidades de medida
        const unidadesResponse = await axios.get(
          `https://api-darpha-production.up.railway.app/api/unidadesMedida`,
          config
        );

        const unidadesData = {};
        unidadesResponse.data.forEach((unidad) => {
          unidadesData[unidad.idunidadmedida] = unidad.unidadmedida;
        })
        setUnidadMedida(unidadesData);
        console.log("Fetched Unidades:", unidadesData); // Verifica las unidades de medida
        
      } catch (error) {
        console.error("Error fetching equipos or types:", error);
      }
    };

    fetchEquipos();
  }, []);

  useEffect(() => {
    let filtered = equipo;

    if (selectedGrupo) {
      filtered = filtered.filter(
        (mach) => mach.idgrupoinstalacion === parseInt(selectedGrupo)
      );
    }

    if (selectedTipo) {
      filtered = filtered.filter(
        (mach) => mach.idtipoinstalacion === parseInt(selectedTipo)
      );
    }

    if (selectedMedida) {
      filtered = filtered.filter(
        (mach) => mach.idmedidainstalacion === parseInt(selectedMedida)
      );
    }

    if (selectedEstado) {
      const estadoValue = selectedEstado === "true";
      filtered = filtered.filter((mach) => mach.estado === estadoValue);
    }

    // Ordenar los equipos por idequipoinstalacion
    filtered.sort((a, b) => a.idequipoinstalacion - b.idequipoinstalacion);


    setFilteredEquipos(filtered);
    console.log("Filtered Equipos:", filtered); // Verifica las máquinas filtradas

    // Determinar visibilidad de las columnas
    const columnsVisibility = {
      idequipoinstalacion: filtered.some((mach) => mach.idequipoinstalacion !== null),
      idtipoinstalacion: filtered.some((mach) => tipoInstalacion[mach.idtipoinstalacion] !== null),
      idgrupoinstalacion: filtered.some((mach) => grupoInstalacion[mach.idpgrupoinstalacion] !== null),
      idmedidainstalacion: filtered.some((mach) => medidaInstalacion[mach.idmedidainstalacion] !== null),
      idunidadmedida: filtered.some((mach) => unidadMedida[mach.idunidadmedida] !== null),
      descripcion: filtered.some((mach) => mach.descripcion !== null),
      stock: filtered.some((mach) => mach.stock !== null),
      uso: filtered.some((mach) => mach.uso != null), // Esto incluirá también '0' o 'false'
      estado: filtered.some((mach) => mach.estado !== null),
      disponibles: filtered.some((mach) => mach.disponibles !== null),
    };

    setVisibleColumns(columnsVisibility);
  }, [selectedGrupo, selectedTipo, selectedMedida, selectedUnidad, selectedEstado, equipo, tipoInstalacion, grupoInstalacion, medidaInstalacion, unidadMedida]);
  
  useEffect(() => {
    // Filtrar datos basados en las selecciones realizadas
    let filteredByGrupo = equipo;
    let filteredByTipo = equipo;
  
    if (selectedGrupo) {
      filteredByGrupo = equipo.filter(
        (mach) => mach.idgrupoinstalacion === parseInt(selectedGrupo)
      );
    }
  
    if (selectedTipo) {
      filteredByTipo = equipo.filter(
        (mach) => mach.idtipoinstalacion === parseInt(selectedTipo)
      );
    }
  
    // Filtrar medidas de instalación válidas considerando grupo y tipo seleccionados
    let filteredMedidas = equipo;
    if (selectedGrupo && selectedTipo) {
      filteredMedidas = equipo.filter(
        (mach) =>
          mach.idgrupoinstalacion === parseInt(selectedGrupo) &&
          mach.idtipoinstalacion === parseInt(selectedTipo)
      );
    } else if (selectedGrupo) {
      filteredMedidas = equipo.filter(
        (mach) => mach.idgrupoinstalacion === parseInt(selectedGrupo)
      );
    } else if (selectedTipo) {
      filteredMedidas = equipo.filter(
        (mach) => mach.idtipoinstalacion === parseInt(selectedTipo)
      );
    }
  
    // Filtrar tipos de instalación válidos
    const tiposInstalacion = {};
    filteredByGrupo.forEach((mach) => {
      if (tipoInstalacion[mach.idtipoinstalacion]) {
        tiposInstalacion[mach.idtipoinstalacion] =
          tipoInstalacion[mach.idtipoinstalacion];
      }
    });
    setFilteredTipoInstalacion(tiposInstalacion);
  
    // Filtrar grupos de instalación válidos
    const gruposInstalacion = {};
    filteredByTipo.forEach((mach) => {
      if (grupoInstalacion[mach.idgrupoinstalacion]) {
        gruposInstalacion[mach.idgrupoinstalacion] =
          grupoInstalacion[mach.idgrupoinstalacion];
      }
    });
    setFilteredGrupoInstalacion(gruposInstalacion);
  
    // Filtrar medidas de instalación válidas basadas en el filtro de grupo y tipo
    const medidasInstalacion = {};
    filteredMedidas.forEach((mach) => {
      if (medidaInstalacion[mach.idmedidainstalacion]) {
        medidasInstalacion[mach.idmedidainstalacion] =
          medidaInstalacion[mach.idmedidainstalacion];
      }
    });
    setFilteredMedidaInstalacion(medidasInstalacion);

  }, [
    selectedGrupo,
    selectedTipo,
    selectedMedida,
    selectedUnidad,
    equipo,
    tipoInstalacion,
    grupoInstalacion,
    medidaInstalacion,
  ]);
  
  // Función para eliminar un filtro específico
  const clearFilter = (filterType) => {
    switch (filterType) {
      case "grupo":
        setSelectedGrupo("");
        break;
      case "tipo":
        setSelectedTipo("");
        break;
      case "medida":
        setSelectedMedida("");
        break;
      case "unidad":
        setSelectedUnidad("");
        break;
      default:
        break;
    }
  };

  return (
    <div className="widget-maquinas">
      <div className="filters-container">
      <div className="filter">
          <select
            id="grupo"
            value={selectedGrupo}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedGrupo(value === "" ? "" : value);
            }}
          >
            <option value="">Todas las grupos</option>
            {Object.entries(filteredGrupoInstalacion).map(([id, nombre]) => (
              <option key={id} value={id}>
                {nombre}
              </option>
            ))}
          </select>
          {selectedGrupo && (
            <i
              className="fa-solid fa-trash"
              onClick={() => clearFilter("grupo")}
            ></i>
          )}
        </div>

        <div className="filter">
          <select
            id="tipoInstalacion"
            value={selectedTipo}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedTipo(value === "" ? "" : value);
            }}
          >
            <option value="">Todos los tipos</option>
            {Object.entries(filteredTipoInstalacion).map(([id, nombre]) => (
              <option key={id} value={id}>
                {nombre}
              </option>
            ))}
          </select>
          {selectedTipo && (
            <i
              className="fa-solid fa-trash"
              onClick={() => clearFilter("tipo")}
              style={{ cursor: "pointer", marginLeft: "5px" }}
            ></i>
          )}
        </div>

        <div className="filter">
          <select
            id="medidaInstalacion"
            value={selectedMedida}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedMedida(value === "" ? "" : value);
            }}
          >
            <option value="">Todas las medidas</option>
            {Object.entries(filteredMedidaInstalacion).map(([id, nombre]) => (
              <option key={id} value={id}>
                {nombre}
              </option>
            ))}
          </select>
          {selectedMedida && (
            <i
              className="fa-solid fa-trash"
              onClick={() => clearFilter("medida")}
              style={{ cursor: "pointer", marginLeft: "5px" }}
            ></i>
          )}
        </div>

        <div className="filter">
          <select
            id="estado"
            value={selectedEstado}
            onChange={(e) => setSelectedEstado(e.target.value)}
          >
            <option value="">Todos los estados</option>
            <option value="true">Existencia</option>
            <option value="false">Agotado</option>
          </select>
        </div>

        <div className="add-button">
          <button onClick={() => navigate("/addmaquina")}>
          {isDesktop ? (
              <>
              <i class="fa-solid fa-plus"></i>
              Añadir Grupo
              </>
              ) 
              : 
              <i class="fa-solid fa-plus"></i>}
          </button>
        </div>
      </div>

      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              {visibleColumns.idequipoinstalacion && <th className="th">Id Equipo</th>}
              {visibleColumns.idgrupoinstalacion && (
                <th className="th">Grupo</th>
              )}
              {visibleColumns.idtipoinstalacion && (
                <th className="th">Tipo</th>
              )}
              {visibleColumns.descripcion && <th className="th">Descripción</th>}
              {visibleColumns.idmedidainstalacion && (
                <th className="th">Medida</th>
              )}
              {visibleColumns.stock && <th className="th">Stock</th>}
              {visibleColumns.uso && <th className="th">Uso</th>}
              {visibleColumns.disponibles && <th className="th">Disponibles</th>}
              {visibleColumns.estado && <th className="th">Estado</th>}
            </tr>
          </thead>
          <tbody>
            {filteredEquipos.map((mach) => (
              <tr key={mach.idequipoinstalacion} className="table-row">
                {visibleColumns.idequipoinstalacion && <td className="td">{mach.idequipoinstalacion || "-"}</td>}
                {visibleColumns.idgrupoinstalacion && (
                  <td className="td">
                    {grupoInstalacion[mach.idgrupoinstalacion] || "-"}
                  </td>
                )}
                {visibleColumns.idtipoinstalacion && (
                  <td className="td">
                    {tipoInstalacion[mach.idtipoinstalacion] || "-"}
                  </td>
                )}
                {visibleColumns.descripcion && <td className="td">{mach.descripcion || "-"}</td>}
                {visibleColumns.idmedidainstalacion && (
                  <td className="td">{medidaInstalacion[mach.idmedidainstalacion] || "-"}</td>
                )}
                {visibleColumns.stock && (
                  <td className="td">
                    {mach.stock && mach.idunidadmedida
                      ? `${mach.stock} ${unidadMedida[mach.idunidadmedida]}`
                      : "-"}
                  </td>
                )}
                {visibleColumns.uso && (
                  <td className="td">
                  {mach.uso && mach.idunidadmedida
                    ? `${mach.stock} ${unidadMedida[mach.idunidadmedida]}`
                    : "-"}
                </td>
                )}
                {visibleColumns.disponibles && <td className="td">{mach.disponibles || "-"}</td>}
                {visibleColumns.estado && <td className="td">{mach.estado===true ? "Existencia" : "Agotado"}</td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WidgetInventory;
