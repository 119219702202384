import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/BottomNavBar.css';
import { googleLogout } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode'; // Importamos jwt-decode

const BottomNavBar = () => {
  const navigate = useNavigate();

  // Función para obtener el rol desde el token JWT
  const getRoleFromToken = () => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (!token) {
      return null; // Si no hay token, devolvemos null
    }

    try {
      // Decodificar el token y extraer el idrol
      const decodedToken = jwtDecode(token);
      return decodedToken.idrol; // Asegúrate de que el token tenga el campo idrol
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };

  // Función para manejar el clic en el ícono de Home
  const handleHomeClick = () => {
    const idrol = getRoleFromToken();

    // Redirigir según el rol
    if (idrol === 1) {
      navigate('/homeadmin'); // Ruta para administrador
    } else if (idrol === 2) {
      navigate('/homeinspector'); // Ruta para técnico
    } else if (idrol === 3) {
      navigate('/homesupervisor'); // Ruta para cliente
    } else {
      navigate('/'); // Ruta por defecto si no hay rol o es un rol no reconocido
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Eliminar token del localStorage
    sessionStorage.removeItem('token'); // Eliminar token de sessionStorage
    localStorage.removeItem('userId'); // Eliminar cualquier otro dato del usuario
    sessionStorage.removeItem('userId');
    googleLogout();
    navigate('/'); // Redirigir a la página de login
  };
  return (
    <div className="bottom-nav">
      <Link onClick={handleHomeClick} className="nav-item">
      <i class="fa-solid fa-house"></i>
      </Link>
      <Link to="/profile" className="nav-item">
      <i class="fa-solid fa-user"></i>
      </Link>
      <Link onClick={handleLogout} className="nav-item">
      <i className="fa-solid fa-right-from-bracket"></i>
      </Link>
    </div>
  );
};

export default BottomNavBar;
