import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles/WidgetMaquinas.css";
import { useNavigate } from "react-router-dom";
import { isDesktop } from "react-device-detect";

function WidgetPlantas() {
  const [plantas, setPlantas] = useState([]); // Cambiado a un array vacío
  const [empresas, setEmpresas] = useState({});
  const [selectedEmpresa, setSelectedEmpresa] = useState(""); // Estado para la empresa seleccionada
  const [filteredPlantas, setFilteredPlantas] = useState([]); // Estado para las plantas filtradas
  const navigate = useNavigate();

  const fetchPlantas = async () => {
    try {
      let token = localStorage.getItem("token");
      if (!token) {
        token = sessionStorage.getItem("token");
      }

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await axios.get(
        "https://api-darpha-production.up.railway.app/api/plantas", // Corregido
        config
      );
      setPlantas(response.data);

      const empresasResponse = await axios.get(
        `https://api-darpha-production.up.railway.app/api/empresas-clientes`,
        config
      );
      const empresasData = {};
      empresasResponse.data.forEach((empresa) => {
        empresasData[empresa.idempresacliente] = empresa.nombreempresa;
      });
      setEmpresas(empresasData);
    } catch (error) {
      console.error("Error fetching plants:", error);
    }
  };

  useEffect(() => {
    fetchPlantas();
  }, []);

  useEffect(() => {
    // Filtrar plantas por la empresa seleccionada
    if (selectedEmpresa) {
      const filtered = plantas.filter(
        (planta) => planta.idempresacliente === parseInt(selectedEmpresa)
      );
      setFilteredPlantas(filtered);
    } else {
      setFilteredPlantas(plantas); // Mostrar todas las plantas si no hay filtro
    }
  }, [selectedEmpresa, plantas]);

  const capitalizeWords = (str) => {
    if (!str) return ""; // Si str es undefined o null, devuelve una cadena vacía
    return str
      .toLowerCase() // Convierte toda la cadena a minúsculas
      .split(' ') // Divide la cadena en palabras
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitaliza solo la primera letra de cada palabra
      .join(' '); // Vuelve a unir las palabras
  };

  return (
    <div className="widget-maquinas">
      <div className="filters-container">
        <div className="filter">
          <select
            id="empresa"
            value={selectedEmpresa}
            onChange={(e) => setSelectedEmpresa(e.target.value)}
          >
            <option value="">Todas las empresas</option>
            {Object.entries(empresas).map(([id, nombre]) => (
              <option key={id} value={id}>
                {nombre}
              </option>
            ))}
          </select>
        </div>

        <div className="add-button">
          <button onClick={() => navigate("/addplanta")}>
          {isDesktop ? (
              <>
              <i class="fa-solid fa-plus"></i>
              Añadir Planta
              </>
              ) 
              : 
              <i class="fa-solid fa-plus"></i>}
          </button>
        </div>
      </div>

      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th className="th">Nombre Planta</th>
              <th className="th">Empresa Cliente</th>
              <th className="th">Ubicación</th>
              <th className="th">Representante</th>
            </tr>
          </thead>
          <tbody>
            {filteredPlantas.map((planta) => (
              <tr key={planta.idplanta} className="table-row">
                <td className="td">{capitalizeWords(planta.nombreplanta) || "-"}</td>
                <td className="td">{capitalizeWords(empresas[planta.idempresacliente]) || "-"}</td>
                <td className="td">{capitalizeWords(planta.direccion) || "-"}</td>
                <td className="td">{capitalizeWords(planta.representante) || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WidgetPlantas;
